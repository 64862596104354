ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
  }
  
  li {
    float: left;
    display: block;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    color: white;
  }

  li:hover {
    color: #B0AEBF;
  }

  .active {
    color: red;
  }