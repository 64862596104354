#resumeheader {
    margin: 0px;
    background-color: #f4f4f4;
}

.pdf-link {
    color: #7e6688;
    font-style: italic;
}

.resume-background {
    /* background-color: #f4f4f4; */
    background: url('./Japanbg4.png') center;
    background-size: auto 100%;
    padding-bottom: 20px;
    padding-top: 20px;
}

.resume-body {
    width: 75%;
    margin: auto;
}

.resume-ul li {
    color: black;
    padding-left: 0px;
}

.title-text {
    font-weight: bold;
}

.alternating-bg {
    /* background-color: #dadee9; */
    background-color: ghostwhite;
}

.resumeIcon {
    padding-top: 10px;
    padding-left: 5px;
    color: #9da1aa;
}