#portfolioheader {
  margin: 0px;
}

.portfolioBackground {
    background-color: #f4f4f4;
  }

.imagestyle {
   padding: 5px;
   height: 20vw;
   object-fit: cover;
}

.portfolioContainer {
  padding-bottom: 20px;
}